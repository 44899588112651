
.ant-table-thead tr th{
  white-space : nowrap !important;
  text-align: center;
  background-color : #0984e3 !important;
  color : #ecf0f1 !important;

}

.ant-table-tbody tr:nth-child(even){
  background-color : #f2f2f2 ;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td{
  /* background-color : #74b9ff !important; */
  /* color : #ecf0f1 !important; */
}

.ant-table-body{
  margin : 0 !important;
}

.ant-table-tbody tr td{
  white-space : nowrap;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;

}

.ant-table-tbody tr td:hover{
  overflow: visible;
  white-space:normal;
  word-wrap: break-word;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}